<template>
	<div class="store__container" :class="storeTheme">
		<NotificationsToast />
		<div ref="pageWrapperRef" v-if="isLoaded" class="white_page_wrapper store-theme" :class="storeTheme">
			<div id="userPageWrapperId" class="white_page_wrapper_inner">
				<div class="mx-auto">
					<div class="site__prev__masking">
						<div v-if="user" class="store-layout nuxt-store-page">
							<div id="fixed-background" class="d-none" />
							<Header
								:full_name="user.full_name"
								:username="user.username"
								:userBio="user.bio"
								:user_image="$optimizeImage(user.user_image, { width: optimalProfileImageSize })"
								:storeTheme="storeTheme"
								:socialIcons="user.data.socials"
								ref="storeHeader"
							/>

							<ExternalLinks v-if="noProducts" class="privacy-policy position-bottom" ref="storeContent" />
							<div class="store-content" v-else ref="storeContent">
								<div class="store-content__button-container">
									<div class="section" v-for="section in sections">
										<div class="section-title" v-if="section.data['title']">{{ section.data['title'] }}</div>
										<div class="overflow-visible w-100">
											<MasonaryWall :items="section.pages">
												<template #default="{ page }">
													<LeadMagnetButton
														v-if="isLeadMagnet(page)"
														class="mb-5"
														:id="`store-block-page_id-${page.page_id}`"
														:key="`page-${page.page_id}-${formTick}`"
														:user="user"
														:title="page.data.button.title"
														:heading="page.data.button.heading"
														:image="$optimizeImage(page.data.button.image, { width: 500 })"
														:tagline="page.data.button.tagline"
														:questions="page.data.product.questions"
														:fcpComplete="fcpComplete"
														@buttonClick="buttonClick(page, $event)"
													/>
													<MediaPreview
														v-else-if="isEmbed(page.data.button.layout)"
														:embedUrl="page.data.product.link.embed_url"
														:embedType="page.data.product.link.embed_type"
														:embedStyle="page.data.product.link.embed_style"
													/>
													<SitePreviewButton
														v-else-if="isLayoutPreview(page.data.button.layout)"
														class="mb-5"
														:id="`store-block-page_id-${page.page_id}`"
														:key="`page-${page.page_id}`"
														:user="user"
														:title="page.data.button.title"
														:heading="page.data.button.heading"
														:image="$optimizeImage(page.data.button.image, { width: 500 })"
														:tagline="page.data.button.tagline"
														:reviews="page.data.product.reviews"
														:product-type="page.data.product.type"
														:price="page.data.product.price"
														:quantity="getProductQuantity(page.data.product)"
														:page-id="page.page_id"
														:product="page.data.product"
														:user-primary-color="userPrimaryColor"
														@buttonClick="buttonClick(page, $event)"
														@calendar-date-selected="preSelectCalendarDate"
														@webinar-session-selected="preSelectWebinarSession"
													/>
													<SiteCalloutButton
														v-else-if="isLayoutCallout(page.data.button.layout)"
														class="mb-5"
														:id="`store-block-page_id-${page.page_id}`"
														:key="`page-${page.page_id}`"
														:user="user"
														:title="page.data.button.title"
														:heading="page.data.button.heading"
														:image="$optimizeImage(page.data.button.image, { width: 500 })"
														:tagline="page.data.button.tagline"
														:reviews="page.data.product.reviews"
														:product-type="page.data.product.type"
														:price="page.data.product.price"
														:quantity="getProductQuantity(page.data.product)"
														:page-id="page.page_id"
														:product="page.data.product"
														:user-primary-color="userPrimaryColor"
														@buttonClick="buttonClick(page, $event)"
													/>
													<SiteButton
														v-else-if="isLayoutButton(page.data.button.layout)"
														class="mb-5"
														:key="`page-${page.page_id}`"
														:user="user"
														:title="page.data.button.title"
														:image="$optimizeImage(page.data.button.image, { width: 100 })"
														:reviews="page.data.product.reviews"
														:product-type="page.data.product.type"
														:price="page.data.product.price"
														:quantity="getProductQuantity(page.data.product)"
														@buttonClick="buttonClick(page)"
													/>
												</template>
											</MasonaryWall>
										</div>
									</div>
								</div>
								<ExternalLinks class="privacy-policy" />
							</div>
							<!-- Blocks -->
							<div v-if="!user.data.hide_store_referral_banner && !isMobileViewingPreview()" ref="toPosition" class="referral-banner">
								<a @click.prevent="goToSignup" class="mb-0 d-flex justify-content-between">
									<StanLogoSvg :width="60" :height="16" />
									<span>Try 14 Days Free</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="loader-wrapper">
			<div class="loader-block">
				<HollowDotsSpinner
					class="d-flex align-items-center"
					:animation-duration="1000"
					:dot-size="16"
					:dots-num="3"
					:color="userPrimaryColor"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import { debounce } from '~/plugins/debounce'
	import { PAGE_TYPES } from '~/constants'
	import HollowDotsSpinner from '~/stan-vue-shared/components/HollowDotsSpinner'
	import Header from '~/stan-vue-shared/components/Header'
	import LeadMagnetButton from '~/stan-vue-shared/components/buttons/LeadMagnetButton'
	import SiteCalloutButton from '~/stan-vue-shared/components/buttons/SiteCalloutButton'
	import SitePreviewButton from '~/stan-vue-shared/components/buttons/SitePreviewButton'
	import SiteButton from '~/stan-vue-shared/components/buttons/SiteButton'
	import ExternalLinks from '~/stan-vue-shared/components/ExternalLinks'
	import MasonaryWall from '~/components/MasonaryWall'
	import NotificationsToast from '~/components/NotificationsToast'
	import MediaPreview from '../../stan-vue-shared/components/MediaPreview.vue'
	import StanLogoSvg from '~/assets/images/stan-logo.svg'
	import { TAG_TYPES_LIST } from '../../constants'
	import { isEmpty } from '~/stan-vue-shared/components/utils'

	const loadCaptcha = async site_key => {
		return new Promise((resolve, reject) => {
			const script = document.createElement('script')
			const scriptTag = document.getElementsByTagName('script')[0]
			script.src = `https://www.google.com/recaptcha/enterprise.js?render=${site_key}`
			scriptTag.parentNode.insertBefore(script, scriptTag)
			script.onload = () => {
				resolve()
			}
		})
	}

	export default {
		name: 'UserIndex',
		components: {
			HollowDotsSpinner,
			Header,
			LeadMagnetButton,
			SitePreviewButton,
			SiteCalloutButton,
			SiteButton,
			ExternalLinks,
			MasonaryWall,
			NotificationsToast,
			StanLogoSvg,
			MediaPreview,
		},
		data() {
			return {
				fcpComplete: false,
				debounceTimeout: null,
				isLoaded: false,
				activePage: 0,
				user: null,
				store: null,
				formTick: 0,
				ref_username: '',
				preSelectedValues: { 0: 0 },
				trackingProps: {},
				tags: {},
			}
		},
		head() {
			const title = '@' + this.user.username
			const description = 'Stan | Linksite'
			const image = this.$optimizeImage(this.user.user_image, { width: this.optimalProfileImageSize })
			const pinterest_tag_id = this.user?.data?.pinterest_tag_id

			return {
				titleTemplate: title,
				title,
				meta: [
					{
						hid: 'description',
						name: 'description',
						content: description,
					},
					{ hid: 'og:title', property: 'og:title', content: title },
					{
						hid: 'og:description',
						property: 'og:description',
						content: description,
					},
					{
						hid: 'og:url',
						property: 'og:url',
						content: this.$config.frontURL + this.user.username,
					},
					{
						hid: 'og:image',
						property: 'og:image',
						content: image,
					},
					{
						hid: 'twitter:title',
						name: 'twitter:title',
						content: title,
					},
					{
						hid: 'twitter:description',
						name: 'twitter:description',
						content: description,
					},
					{
						hid: 'twitter:image',
						name: 'twitter:image',
						content: image,
					},
					{
						hid: 'p:domain_verify',
						name: 'p:domain_verify',
						content: pinterest_tag_id,
					},
				],
			}
		},
		computed: {
			showReferralBannerExperiment() {
				return this.$isFlag('referral-banner-experiment')
			},
			pageData() {
				return this.store.pages[this.activePage]
			},
			userPrimaryColor() {
				return (
					(this.pageData && this.pageData.data && this.pageData.data.primary_color) ||
					(this.store && this.store.data && this.store.data.primary_color) ||
					this.$store.getters.getUserPrimaryColor
				)
			},
			noProducts() {
				if (this.sections && this.sections.length > 0) {
					const hasProducts = this.sections.findIndex(section => section.pages && section.pages.length > 0) > -1
					return !hasProducts
				}
				return true
			},
			pages() {
				return this.store.pages
			},
			sections() {
				if (this.store.sections && this.store.sections.length > 0) {
					return this.store.sections.filter(section => section.pages.length > 0)
				}
				return []
			},
			storeTheme() {
				if (this.$route.query?.theme) {
					return this.$route.query.theme
				}
				return this.store.data.theme || 'default'
			},
			optimalProfileImageSize() {
				return 500
			},
		},
		async asyncData({ params, $axios, store, redirect, req }) {
			// When routing to this page sometimes `req` is undefined
			const referer = req?.headers?.referrer || req?.headers?.referer
			// Note that mobile app also sets the referer to `NUXT_ENV_ADMIN_URL` do not change without accounting for mobile
			const ignoreCache = false || (referer && referer.includes(process.env.NUXT_ENV_ADMIN_URL))

			async function getStores(username, type) {
				if (!username || username.length > 254) return null
				try {
					var params = {
						username: username,
						type: type,
					}
					if (!!ignoreCache) {
						params.ignore_cache = true
					}
					return await $axios.$get('v1/stores', {
						baseURL: process.env.NUXT_ENV_PYTHON_API,
						params: params,
					})
				} catch (err) {
					console.error('error get stores data: ', err)
					if (err?.response?.data?.reason === 'account_inactive') {
						return 'account_inactive'
					} else if (err?.response?.data?.reason === 'subscription_inactive') {
						return 'subscription_inactive'
					}
					return null
				}
			}
			const [storesResp] = await Promise.all([getStores(params.username, 'linksite')])

			if (storesResp === 'account_inactive' || storesResp === null) {
				redirect('/')
				return {}
			}

			if (storesResp === 'subscription_inactive') {
				redirect('/unavailable')
				return {}
			}

			if (!storesResp.store || !storesResp.user) {
				redirect('/')
				return {}
			}
			const user = storesResp.user
			if (typeof user.data.socials === 'string') {
				user.data.socials = JSON.parse(user.data.socials)
			}
			const storePages = storesResp.store.pages.filter(page => !page.data?.location || page.data?.location === 'store')
			storesResp.store.pages = storePages
			store.commit('setUser', user)
			return { user: storesResp.user, store: storesResp.store }
		},
		async mounted() {
			this.isLoaded = true
			this.ref_username = this.$route.params.username
			await loadCaptcha(this.$config.captcha_site_key)
			this.$cookies.set('ref', $nuxt.$route.params.username)
			this.$sessionStorage.setItem('LastVisitStore', this.$route.params.username)
			let props = {
				type: 'store_home',
				storeId: this.store.store_id,
				storeType: this.store.type,
				// remove once we are completely transitioned
				page: 'creator_home',
				source: 'link-site',
				site: 'linkSite',
			}

			const utmMedium = this.$route.query.utm_medium ?? this.$cookies.get('utm_medium')
			const utmSource = this.$route.query.utm_source ?? this.$cookies.get('utm_source')
			const utmCampaign = this.$route.query.utm_campaign ?? this.$cookies.get('utm_campaign')
			const referrer = window.document.referrer || this.$cookies.get('referrer')
			const currTags = this.$cookies.get('tags') || {}
			this.tags = currTags

			if (referrer) {
				this.$cookies.set('referrer', referrer)
				this.trackingProps['referrer'] = referrer
			}
			if (utmMedium) {
				this.$cookies.set('utm_medium', utmMedium)
				this.trackingProps['utmMedium'] = utmMedium
			}
			if (utmSource) {
				this.$cookies.set('utm_source', utmSource)
				this.trackingProps['utmSource'] = utmSource
			}
			if (utmCampaign) {
				this.$cookies.set('utm_campaign', utmCampaign)
				this.trackingProps['utmCampaign'] = utmCampaign
			}

			// check if query params have tags we want to track, and add them to current tracked tags on user
			for (const tag of TAG_TYPES_LIST) {
				const tagQuery = this.$route.query[tag]
				if (tagQuery !== undefined) {
					currTags[tag] = tagQuery
					this.tags[tag] = tagQuery
				}
			}

			!isEmpty(currTags) && this.$cookies.set('tags', currTags)
			if (!isEmpty(this.tags)) props['tags'] = this.tags
			if (!isEmpty(this.trackingProps)) props = { ...props, ...this.trackingProps }
			if (this.$cookies.get('back') === undefined) {
				this.$stanAnalytics('store-visit', {
					meta: { username: this.$route.params.username },
					props,
				})
			}

			const documentStyles = document.documentElement.style

			const storePrimaryColor = this.store.data.primary_color || this.user.color_code || '#5383ff'
			const storeSecondaryColor = this.store.data.secondary_color

			this.$addThemeColorsToDocument(this.storeTheme, storePrimaryColor, storeSecondaryColor, this.store.data.primary_font)

			documentStyles.setProperty('--stan-store-background-url', `url(${this.user.user_image})`)

			if (this.storeTheme == 'spotlight') {
				document.addEventListener(
					'scroll',
					() => {
						documentStyles.setProperty('--scroll-position-y', window.scrollY)
					},
					{ passive: true }
				)
			}
			const storeHeader = this.$refs.storeHeader?.$el || this.$refs.storeHeader
			const storeContent = this.$refs.storeContent?.$el || this.$refs.storeContent

			// Check if refs are DOM elements
			if (storeHeader && storeContent) {
				storeHeader.addEventListener('wheel', e => {
					e.preventDefault() // Prevent default scroll behavior
					storeContent.scrollTop += e.deltaY // Forward scroll to storeContent
				})
			} else {
				console.error('storeHeader or storeContent ref not found!')
			}
			const urlParams = new URLSearchParams(window.location.search)
			if (urlParams.get('mobile_app_viewing_preview') === 'true') {
				// Prevent clicks and mouse presses
				setTimeout(() => {
					this.disableInteractions()
				}, 0)
			}
		},
		updated: debounce(function () {
			if (!this.fcpComplete) {
				this.$nextTick(() => {
					this.fcpComplete = true
				})
			}
		}, 200),
		destroyed() {},
		methods: {
			disableInteractions() {
				// Disable buttons
				const buttons = document.querySelectorAll('button')

				buttons.forEach(button => {
					button.disabled = true
					button.style.pointerEvents = 'none'
				})

				// Disable anchor tags
				const anchorTags = document.querySelectorAll('a')
				anchorTags.forEach(anchor => {
					anchor.href = 'javascript:void(0)'
					anchor.style.pointerEvents = 'none'
				})

				// Disable input fields
				const inputFields = document.querySelectorAll('input, textarea, select')
				inputFields.forEach(input => {
					input.disabled = true
					input.style.pointerEvents = 'none'
				})
			},
			saveLeadMagnet(page, data) {
				const send = {
					page_id: page.page_id,
					name: data.name,
					email: data.email,
					phone_number: data.phone_number,
					questions: [],
				}
				send.transaction_origin = 'user'
				send.charge_type = 'destination'
				if (!isEmpty(this.trackingProps)) send['tags'] = this.trackingProps
				if (!isEmpty(this.tags)) send['tags'] = { ...send['tags'], ...this.tags }
				const questions = page.data.product.questions
				if (questions) {
					questions.forEach((question, index) => {
						send.questions.push({
							question: question.content,
							answer: data.answers[index],
						})
					})
				}
				grecaptcha.enterprise.ready(async () => {
					try {
						const token = await grecaptcha.enterprise.execute(this.$config.captcha_site_key, {
							action: 'FreeProductCompletionIntent',
						})

						send.token = token

						const response = this.$axios.post('v1/pages/completed/lead-magnet', send)

						this.$notify({
							group: '1',
							title: "You're in!",
							text: "I'll be in touch with you soon.",
							type: 'success',
						})

						this.formTick += 1
						if (window.parent) window.parent.postMessage({ product_purchased: true }, process.env.NUXT_ENV_ADMIN_URL)
						// Fire Analytic events
						if (this.user.data?.analytics?.facebook?.token && this.$fb) {
							this.$fb.track('Lead')
						}
						if (this.user.data?.analytics?.tiktok?.token && this.$tt) {
							this.$tt.track('SubmitForm', { content_id: page.page_id })
						}
					} catch (error) {
						const text =
							error?.response?.data?.message ??
							'Please reach out to our support at <a href="mailto:friends@stanwith.me">friends@stanwith.me</a>'

						this.$notify({
							group: '1',
							title: 'Something went wrong',
							text,
							type: 'error',
						})

						this.$sentry.captureException(new Error(error))
					}
				})
			},
			openStanReferralLink() {
				let signupUrl = `${this.$config.frontURL}?ref=${this.ref_username}`
				if (window.document.referrer) {
					signupUrl = `${signupUrl}&referrer=${window.document.referrer}`
				}

				let url = new URL(signupUrl)
				url.searchParams.set('utm_source', 'stan-store-link')
				url.searchParams.set('utm_medium', 'redirect')
				url.searchParams.set('utm_campaign', 'storefront')

				window.location.href = url.href
			},
			openLink(page) {
				// Check if stan affiliate and append username
				const linkUrl = new URL(page.data.product.link.url)
				const frontendURI = process.env.NUXT_ENV_FRONT_URL.replace('www.', '')
				if (linkUrl.href.includes(frontendURI) && !!linkUrl.searchParams.get('al')) {
					linkUrl.searchParams.set('su', this.user.username)

					// Analytics to determine what information we have available for affiliate codes to be used in determining the optimal solution
					this.$stanAnalytics('affiliate_code_link_clicked_from_store_page', {
						meta: { username: this.$route.params.username },
						props: {
							storeId: this.store.store_id,
							storeType: this.store.type,
							pageId: page.page_id,
							pageType: page.type,
							order: page.order,
							showPrice: false,
							showReviews: !!page.data?.reviews?.length,
							buttonLayout: page.data.button.layout,
							buttonTitle: page.data.button.title,
							buttonHeading: page.data.button.heading,
							buttonTagline: page.data.button.tagline,
							product: `${page.data.product.type}`,
							pageOrder: page.order,
							page: 'creator_home',
							site: 'linkSite',
							source: 'link-site',
							session_last_visit_store: this.$sessionStorage.getItem('LastVisitStore'),
							query_username: this.$route.query?.username || null,
							cookie_ref: this.$cookies.get('ref') || null,
							document_ref: window.document.referrer || null,
							affiliate_code: linkUrl.searchParams.get('al'),
							affiliated_username: linkUrl.searchParams.get('su'),
						},
					})
				}
				window.location.href = linkUrl
			},
			openStorePage(page, data) {
				const preSelectValue = data?.preSelectValue
				const query = {}
				let path = undefined
				if (preSelectValue && this.preSelectedValues[page.page_id]) {
					query.selected = this.preSelectedValues[page.page_id]
				}
				if (page.slug) {
					path = `/${this.$route.params.username}/p/${page.slug}`
				} else if (page.page_id) {
					path = `/${this.$route.params.username}/${this.store.slug}/page/${page.page_id}`
				}
				if (path) this.$router.push({ path, query })
			},
			buttonClick(page, data) {
				const product = page.data.product
				const showPrice = ![PAGE_TYPES.LEAD_MAGNET, PAGE_TYPES.LINK, PAGE_TYPES.STAN_REFERRAL_LINK].includes(page.type)
				this.$stanAnalytics('store-button-click', {
					meta: { username: this.$route.params.username },
					props: {
						storeId: this.store.store_id,
						storeType: this.store.type,
						pageId: page.page_id,
						pageType: page.type,
						order: page.order,
						showPrice,
						showReviews: !!page.data?.reviews?.length,
						buttonLayout: page.data.button.layout,
						buttonTitle: page.data.button.title,
						buttonHeading: page.data.button.heading,
						buttonTagline: page.data.button.tagline,
						product: `${product.type}`,
						pageOrder: page.order,
						page: 'creator_home',
						site: 'linkSite',
						source: 'link-site',
					},
				})

				if (product.type === PAGE_TYPES.LEAD_MAGNET) {
					this.saveLeadMagnet(page, data)
				} else if (product.type === PAGE_TYPES.LINK || product.type === PAGE_TYPES.STAN_REFERRAL_LINK) {
					if (this.user.data?.analytics?.facebook?.token && this.$fb) {
						this.$fb.track('ViewContent')
					}
					if (this.user.data?.analytics?.tiktok?.token && this.$tt) {
						this.$tt.track('ViewContent', { content_id: page.page_id })
					}
					if (product.type === PAGE_TYPES.STAN_REFERRAL_LINK) {
						this.openStanReferralLink()
					} else {
						this.openLink(page)
					}
				} else {
					this.openStorePage(page, data)
				}
			},
			isLayoutPreview(layout) {
				// Only types that allow pro view (for now 'meeting', 'webinar', 'course', 'digital-download')
				return layout?.toLowerCase() === 'preview'
			},
			isLayoutCallout(layout) {
				return layout?.toLowerCase() === 'callout'
			},
			isLayoutButton(layout) {
				return layout?.toLowerCase() === 'button'
			},
			isEmbed(layout) {
				return layout?.toLowerCase() === 'embed'
			},
			isLeadMagnet(page) {
				return page.data.product.type === PAGE_TYPES.LEAD_MAGNET
			},
			getProductQuantity(product) {
				return product?.inventory?.manage ? product.inventory.stock : undefined
			},
			goToSignup() {
				this.$stanAnalytics('store-referral-click', {
					meta: { username: this.$route.params.username },
					props: {
						location: 'store-bottom-link',
					},
				})
				let signupUrl = `${this.$config.frontURL}?ref=${this.ref_username}&utm_source=stan-store&utm_medium=redirect&utm_campaign=bottom-page-link`
				if (window.document.referrer) {
					signupUrl = `${signupUrl}&referrer=${window.document.referrer}`
				}
				window.open(signupUrl)
			},
			preSelectCalendarDate(value) {
				this.preSelectedValues[value.pageId] = value.date
			},
			preSelectWebinarSession(value) {
				this.preSelectedValues[value.pageId] = value.session.startDateTime
			},
			isMobileViewingPreview() {
				// Get the current URL
				const queryString = window.location.search
				const urlParams = new URLSearchParams(queryString)
				const isViewingPageThroughApp = urlParams.has('mobile_app_viewing_preview') && urlParams.get('mobile_app_viewing_preview') === 'true'

				// Check if the 'mobile_viewing_preview' parameter exists and has a truthy value + send analytics
				if (isViewingPageThroughApp) {
					this.$stanAnalytics('viewing-store-preview-through-app', {
						meta: { username: this.$route.params.username },
					})
				}

				return isViewingPageThroughApp
			},
		},
	}
</script>
<style lang="scss" scoped>
	@import '~/stan-vue-shared/assets/styles/themes/index.scss';
	@import '~/stan-vue-shared/assets/styles/themes/responsive/index.scss';
</style>
<style lang="scss">
	#userPageWrapperId {
		width: 100%;
		.white_page_wrapper {
			overflow-y: hidden;
		}
	}
	.store-layout {
		display: flex;
		width: 100%;
		min-height: 100vh;
		padding: 0;
		justify-content: space-evenly;
		flex-wrap: wrap;
		@media (min-width: 1344px) {
			flex-wrap: unset;
			height: 100vh;
		}
	}

	.position-bottom {
		position: absolute;
		bottom: 0;
	}
	.privacy-policy {
		padding-bottom: 60px;
	}
	.referral-banner {
		position: fixed;
		z-index: 3;
		bottom: 0;
		width: -webkit-fill-available;
		margin-bottom: 10px;
		color: var(--stan-store-theme-primary-primary-color);
		font-weight: 800;
		font-family: 'Plus Jakarta Sans';
		letter-spacing: 0.005em;
		font-size: 13px;
		line-height: 16px;

		a {
			--stan-store-theme-primary-primary-color: #6355ff;
			--stan-store-theme-primary-light-color: #ebebff;
			border-radius: 50px;
			max-width: min(95vw, 455px);
			padding: 14px 15px;
			margin: auto;
			color: var(--stan-store-theme-primary-primary-color);
			background: var(--stan-store-theme-primary-light-color);
		}

		.referral-banner__icon {
			height: 1rem;
		}

		/* Desktop styling is applied to IPad and up */
		@media (min-width: 768px) {
			width: auto;
			left: 0;
			min-width: 300px;
			margin-bottom: 0px;

			a {
				border-radius: 0 8px 0 0;
			}
		}
	}
</style>
<style>
	.grecaptcha-badge {
		display: none !important;
	}
</style>
